import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Fortune from '@common/Fortune';
import Cafe from '@sections/Cafe';
import Footer from '@sections/Footer';

const CafePage = ({ data }) => {
  const {
    allMdx: { edges },
  } = data;
  const menus = edges.map((e) => e.node);

  return (
    <Layout>
      <Navbar hideMenu />
      <Cafe menus={menus} />
      <Fortune />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: {
        slug: { regex: "/cafe/i" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          body
          frontmatter {
            slug
            date(formatString: "MMMM YYYY")
            title
            excerpt
          }
        }
      }
    }
  }
`;

export default CafePage;
