import React from 'react';
import { Section, Container } from '@components/global';
import Post from '@article/Post/post';

const Cafe = ({ menus }) => {
  return (
    <Section id="cafe">
      <Container>
        {menus &&
          menus.map((menu, i) => <Post post={menu} key={i} hideProgress />)}
      </Container>
    </Section>
  );
};

export default Cafe;
